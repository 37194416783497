/* FAQ - MOBILE FIRST (~ TABLET) */
.c-tab-menu {
    &::before{
        position:absolute;
        left: 0;
        content: '';
        height: 8px;
        width: 100%;
        background: linear-gradient(to right,#30a2bf 12.01%,#315973 60.41%);
        top: 0;
    }
    display: flex;
    flex-flow: column;
    margin: 10px auto;
    margin-top: 0;
    flex-wrap: wrap;

    &__nav {  
        display: flex;
        height: 62px;
        overflow-x: auto;
        overflow-y: hidden;
        width: auto;
        margin-bottom: 16px;
        margin-top: 8px;
        
        &-container{
            position: absolute;
            left: 0;
            width: 100%;
            border-bottom: 1px solid @grey-30;
            overflow-x: auto;
            scrollbar-width: none;
            padding-left: 16px;
        }
        &-cont-item{
            display: flex;
            margin-top: 16px;
        }
        .btn-minimized{
            display: none;
        }
        .cmp-image {
            display: none;
        }
        &-item {
            cursor: pointer;
            display: flex;
            flex-flow: column;
            
            font-size: 16px;
            line-height: 24px;
            font-weight: 300;
            text-align: center;
            padding: 8px 4px;
            margin: 0px 1px;
            margin-right: 24px;
            align-items: center;
            justify-content: center;
            clear: both;
            white-space: nowrap;
            &:last-child{
                padding-right: 16px;
            }
            &-active {
                font-weight: 500;
                border-bottom: 1px solid #30A2BF;
            }
            .svg-icon {
                display: none;
            }
            & > a.cmp-text__paragraph {
                text-decoration: none;
                color: #000000; 
            }
        }
        // responsive-grid
        .aem-Grid {
            cursor: pointer;
            // display: flex;
            display: none;
            .c-textimage {
                background: @grey-30;
                height: 62px;
                padding: 10px 4px;
                margin: 0px 1px;
                min-width: 82px;
                max-width: 100px;
                > * {
                    width: 100%;
                }
                &:hover {
                    color: @grey-90;
                }
                .icon {
                    display: none;
                }
                .generic-text {
                    width: 100%;
                    .cmp-text {
                        width: 100%;
                        color: @grey-90;
                        font-size: 12px;
                        line-height: 20px;
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }
            .generic-text {
                .cmp-text {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: center;
                    letter-spacing: 0.0015em;
                    color: #000000; 
                    p {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
        .generic-container .aem-Grid {
            display: block;
        }

    }
    &__content {
        width: 100%;
        //need to adapt compnent yb-loyalty-hero
        .c-l-hero{
            margin-top: -4px;
        }
        //
        .accordion-item-faq {
            > .accordion-toggle {
                border-bottom: 2px solid @grey-30;
                flex-direction: row-reverse;
                justify-content: space-between;
                &:hover {
                    color: @grey-90;
                }
            }
            &:after {
                content: none;
            }
            > .accordion-content {
                font-size: 18px;
                line-height: 28px;
            }
            &.active {
                > .accordion-content {
                    margin: 10px 0px;
                }
            }
        }
        &--ctas {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 40px;
            .cta {
                margin-right: 16px;
                .c-cta {
                    min-width: 260px;
                }
            }
        }

        //need to adapt cmp central-image for myb-area-privada checkin image
        .central-image .cmp-image {
            max-height: 400px;
            width: auto;
        }
        //
    }
    .breadcrumb {
        flex-basis: 100%;
        margin-top: 26px;
        margin-bottom: 18px;
        .cmp-breadcrumb {
            display: block;
        }
    }

    .c-myb-content {
        margin-bottom: 38px;
    }
}

/* FAQ - DESKTOP */
@media (min-width: @desktop) {
    .c-tab-menu {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: center;
        &::before{
            width: 100vw;
            left: 50%;
            transform: translateX(-50%);
            top: -1px;
        }

        &__nav {
            &-container{
                padding-right: 32px;            
                position: inherit;
                width: 100%;
                border-bottom: none;
                overflow-x: inherit;
                padding-left: 0;
                
            }
            .icon-nav-desktop{
                display: block;

                .cmp-image {
                    width: 149px;
                }
            }
            .icon-nav-minimized{
                display: none;
            }
            &-cont-item{
                display: block;
                margin-top: 0;
            }
            box-shadow: 20px 0px 16px -16px rgba(0,0,0,0.1);
            position: relative;
            flex-flow: column;
            height: auto;
            width: 271px;
            overflow: inherit;
            padding-top: 67px;
            margin: 0px 20px 0px 0px;
            flex-shrink: 0;
            transition-duration: 0.2s;
            .btn-minimized{
                z-index: 2;
                width: 32px;
                height: 32px;
                background-color: @grey-90;
                position: absolute;
                right: -32px;
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                span{
                    color:white;
                }
            }
            .cmp-image {
                display: block;
                width: 218px;
                height: auto;
                margin-bottom: 53px;
                img {
                    object-fit: fill;
                }
            }
            &-item {
                flex-flow: row;
                background: transparent;
                font-size: 16px;
                line-height: 24px;
                font-weight: 300;
                text-align: left;
                padding: 0 0 0 8px;
                margin: 0px 0px 24px;
                justify-content: flex-start;
                min-width: inherit;
                max-width: inherit;
                &:last-child{
                    margin-bottom: 53px;
                    padding-right: 0px;
                }
                &-active {
                    background: transparent;
                    color: @grey-90;
                    font-weight: bold;
                    font-weight: 600;
                    border-bottom: none;
                }
                .svg-icon {
                    .mod--size-24;
                    display: inherit;
                    margin-right: 9px;
                    flex-shrink: 0;
                }
            }
            // responsive-grid
            .aem-Grid {
                margin: 0;
                padding: 0;
                margin-top: 25px;
                display: block;
                .generic-container .aem-Grid {
                    display: block;
                    // padding: 21px 0 32px;
                    margin-top: 0;
                }
                .generic-container{
                    // padding-left: 16px;
                    box-shadow: none;
                    .cta {
                        a{
                            font-size: 16px;
                            font-weight: 400
                        }
                        
                    }
                }
                .icon {
                    .mod--size-32;
                    display: inherit;
                    // margin-right: 9px;
                    flex-shrink: 0;
                }
                .generic-text {
                    .cmp-text, .cmp-text p {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 24px;
            
                    }
                }
            }
           
        }
        &__content {
            padding-left: 25px;
            padding-top: 64px;
            width: ~"calc(100% - 291px)";


            //need to adapt compnent yb-loyalty-hero
            .c-l-hero{
                margin-left: -45px;
                margin-top: -57px;
            }
            &.mode-edit{
                .c-l-hero{
                    margin-top: 0px;
                }
            }
            //

            //need to adapt cmp central-image for myb-area-privada checkin image
            .central-image .cmp-image {
                max-height: 400px;
                width: auto;
            }
            //
           
            .accordion-item-faq {
                > .accordion-toggle {
                    border-bottom: 1px solid @grey-30;
                    &:hover {
                        color: @grey-90;
                    }
                }
                &:after {
                    content: none;
                }
                &.active {
                    > .accordion-content {
                        margin: 10px 0px;
                    }
                }
            }

            &--ctas {
                margin-top: 80px;
            }
        } 
        .breadcrumb {
            margin-top: 72px;
            margin-bottom: 64px;
        }

        .c-myb-content {
            margin-bottom: 68px;
        }

          /* MINIMIZED MODE */
        &.minimized{
            
            .c-tab-menu__nav{
                width: 83px; 
                transition-duration: 0.2s;       
                &-item {
                    padding-left: 8px;
                    .cmp-text__paragraph {
                    display: none;
                    }
                }
                &-container-grid{
                    display: none;
                }
                .icon-nav-desktop{
                    display: none;
                }
                .icon-nav-minimized{
                    display: block;
                    .cmp-image{
                        width: 48px;
                        height: auto;
                    }
                }
                .btn-minimized{
                    .icon-arrow-left{
                        transform: rotate(-180deg);
                        transition-duration: 0.2s;
                    }
                }            
            }
            .c-tab-menu__content {
                width: ~"calc(100% - 103px)";
                transition-duration: 0.2s;
            }
             //need to adapt cards of compnent yb-loyalty-hero
            .c-l-hero{
                &__main {  
                    &__cards{
                        &-1{
                            width: 50%;
                        }
                        &-2{
                            width: 50%;
                        }
                    }
                }
            }
            //

        }
        /* */
    }
}

@media (min-width: 1313px) {
    .c-tab-menu {
        &.minimized {
            .c-tab-menu {
                &__nav {
                    width: 114px;
                }
        
                &__content {
                    width: calc(100% - 134px);
                }
            }
        }

        &__nav {
            width: 303px;

            &-container {
                padding-left: 32px;
            }
        }

        &__content {
            width: calc(100% - 323px);
        }
    }
}


/* Editorial */
.faq.cq-Editable-dom {
    .c-tab-menu {
        div:first-child {
            flex-basis: 100%;
        }
    }
}